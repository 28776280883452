<template>
  <div class="app-container">
    <div class="mytabel table-sm mr-0 ml-0 p-0">
      <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
        <div
          class="
            col-7
            p-0
            align-self-center
            font-weight-bold
            d-flex
            align-items-center
          "
        >
          <h5 class="d-inline mr-2 font-weight-bold">
            {{ $t("message.groups") }}
          </h5>
          <crm-refresh @c-click="refresh()"></crm-refresh>
          <div class="text-center d-flex sorddata ml-3">
            <el-input
              class="ml-3"
              size="mini"
              :placeholder="$t('message.search')"
              prefix-icon="el-icon-search"
              v-model="filterForm.search"
              clearable
            ></el-input>
          </div>
        </div>
        <div
          class="
            col-5
            align-items-center align-self-center
            text-right
            pr-0
            d-flex
            justify-content-end
          "
        >
          <el-button
            class="mr-2"
            size="mini"
            @click="drawer.create.status = true"
            icon="el-icon-circle-plus-outline"
            >{{ $t("message.create") }}</el-button
          >
          <el-dropdown class="mr-2">
            <el-button size="mini" icon="el-icon-setting">
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(column, index) in columns" :key="index">
                <el-checkbox
                  :checked="column.show"
                  @change="column.show = !column.show"
                  >{{ column.title }}</el-checkbox
                >
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <table
        class="table table-bordered table-hover mr-0 ml-0 p-0 bg-white"
        v-loading="loadingData"
      >
        <crm-pagination
          :pagination="pagination"
          @c-change="updatePagination"
        ></crm-pagination>
        <thead>
          <tr>
            <th v-if="columns.id.show">{{ columns.id.title }}</th>
            <th v-if="columns.name.show">{{ columns.name.title }}</th>
            <th v-if="columns.parent.show">{{ columns.parent.title }}</th>
            <th v-if="columns.created_at.show">
              {{ columns.created_at.title }}
            </th>
            <th v-if="columns.updated_at.show">
              {{ columns.updated_at.title }}
            </th>
            <th v-if="columns.settings.show">{{ columns.settings.title }}</th>
          </tr>

          <tr>
            <th v-if="columns.id.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.id"
                class="id_input"
                :placeholder="columns.id.title"
              ></el-input>
            </th>
            <th v-if="columns.name.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.name"
                :placeholder="columns.name.title"
              ></el-input>
            </th>
            <th v-if="columns.parent.show">
              <categories v-model="filterForm.parent_id"></categories>
            </th>
            <th v-if="columns.created_at.show">
              <el-date-picker
                size="mini"
                v-model="filterForm.created_at"
                :placeholder="columns.created_at.title"
                type="date"
                :format="'dd.MM.yyyy'"
                :value-format="'dd.MM.yyyy'"
              ></el-date-picker>
            </th>
            <th v-if="columns.updated_at.show">
              <el-date-picker
                size="mini"
                v-model="filterForm.updated_at"
                :placeholder="columns.updated_at.title"
                type="date"
                :format="'dd.MM.yyyy'"
                :value-format="'dd.MM.yyyy'"
              ></el-date-picker>
            </th>
            <th v-if="columns.settings.show"></th>
          </tr>
        </thead>
        <transition-group name="flip-list" tag="tbody">
          <tr
            v-for="category in list"
            :key="category.id"
            class="cursor-pointer"
          >
            <td v-if="columns.id.show">{{ category.id }}</td>
            <td v-if="columns.name.show">{{ category.name }}</td>
            <td v-if="columns.parent.show">
              {{ category.parent ? category.parent.name : "" }}
            </td>
            <td v-if="columns.created_at.show">{{ category.created_at }}</td>
            <td v-if="columns.updated_at.show">{{ category.updated_at }}</td>
            <td v-if="columns.settings.show" class="settings-td">
              <crm-setting-dropdown
                :model="category"
                name="categories"
                :actions="actions"
                @edit="edit"
                @delete="destroy"
              ></crm-setting-dropdown>
            </td>
          </tr>
        </transition-group>
      </table>
    </div>

    <el-drawer
      size="50%"
      :visible.sync="drawer.create.status"
      :with-header="false"
      :ref="drawer.create.name"
      :before-close="beforeClose"
    >
      <CrmCreate :drawer-name="drawer.create.name" />
    </el-drawer>
  </div>
</template>
<script>
import CrmCreate from "./components/crm-create";
import { mapGetters, mapActions } from "vuex";
import list from "@/utils/mixins/list";
import drawer from "@/utils/mixins/drawer";
import categories from "@/components/inventory-select/categories";

export default {
  components: {
    CrmCreate,
    categories,
  },
  mixins: [list, drawer],
  data() {
    return {
      loadingData: false,
      drawer: {
        create: {
          name: "create",
          status: false,
        },
      },
      actions: ["edit", "delete"],
    };
  },
  computed: {
    ...mapGetters({
      list: "categories/list",
      columns: "categories/columns",
      pagination: "categories/pagination",
      filter: "categories/filter",
      sort: "categories/sort",
    }),
  },
  methods: {
    ...mapActions({
      getCategories: "categories/index",
      updateSort: "categories/updateSort",
      updateFilter: "categories/updateFilter",
      updateColumn: "categories/updateColumn",
      updatePagination: "categories/updatePagination",
      showCategory: "categories/show",
      empty: "categories/empty",
      delete: "categories/destroy",
      refresh: "categories/refreshData",
    }),
    fetchData() {
      const query = { ...this.filter, ...this.pagination, ...this.sort };
      if (!this.loadingData) {
        this.loadingData = true;
        this.getCategories(query)
          .then((response) => {
            this.loadingData = false;
          })
          .catch((error) => {
            this.loadingData = false;
          });
      }
    },
    beforeClose(done) {
      this.empty();
      this.refreshData();
      done();
    },
    refreshData() {
      this.refresh().then(() => {
        this.filterForm = JSON.parse(JSON.stringify(this.filter));
      });
    },
    async edit(model) {
      await this.showCategory(model.id).then(() => {
        this.drawer.create.status = true;
      });
    },
    destroy(model) {
      this.delete(model.id)
        .then((res) => {
          this.$alert(res);
          this.fetchData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
